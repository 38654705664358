<template>
  <div>
    <div @click="handleClick">
      <slot></slot>
    </div>
    <ali-oss
      :id="id"
      ref="updateFile"
      :upObj="upObj"
      :isAuto="isAuto"
      :filter="filter"
      :isMore="isMore"
      :callBack="callBack"
      :maxNum="maxNum"
      :oldList="oldList"
      :setType="setType"
      :isResearch="isResearch"
      @changeFile="changeFile"></ali-oss>
  </div>
</template>
<script>
import aliOss from '@/components/ali-oss.vue'
import { UploadOutlined } from '@ant-design/icons-vue'
export default ({
  props: {
    id: {
      type: String,
      default: 'file'
    },
    upObj: {
      type: Array,
      required: true
    },
    isAuto: {
      type: Boolean,
      default: false
    }, // 是否自动上传，默认不自动上传
    filter: {},
    isMore: {},
    callBack: {},
    maxNum:{},
    setType: {},
    isResearch:{},
    oldList: {
      type: Array,
      default: () => {
        return []
      }
    }, // 传入进来的文件列表，（编辑时，编辑后的默认列表）
  },
  components: {
    UploadOutlined,
    aliOss
  },
  data(){
    return{

    }
  },
  methods:{
    handleClick(){
      this.$refs.updateFile.clearFile()
      const ele = document.getElementById(this.id)
      if (ele) {
        ele.click()
      }
    },
    // 文件选择的回调
    changeFile(lists){
      this.$emit('changeFile', lists)
    },
    // 上传
    fetchSend(callback){
      this.$refs.updateFile.fetchSend(callback)
    },
    deleteFile(){
      this.$refs.updateFile.clearFile()
    },
    clearFiles(){
      this.$refs.updateFile.clearFiles()
    }
  }
})
</script>
